var minicart = require('base_global/components/miniCart');
var utils = require('base_global/utils');
var tealiumEnable = require('int_tealium_sfra/tealiumEnable');

/**
 * Update order summary in cart page
 * @param {Object} data cart objects
 */
function updateMiniCartTotal(data) {
    // for US site we are using this key to show grand total
    if ($('.grand-total-sum').hasClass('order-cost-item-value')) {
        $('.grand-total-sum').empty().append(data.totals.grandTotal);
    } else {
        $('.grand-total-sum').empty().append(data.totals.totalNetPrice);
    }

    // for CA site we are using this key to show grand total
    if (data.totals.subTotal) {
        $('.estimated-cost-subtotal').empty().append(data.totals.subTotal);
    }
    // for US site we are using this key to show subtotal
    // $('.sub-total').empty().append(data.totals.subTotal);
    $('.mini-cart-grand-total').empty().append(typeof(data.totals.subTotalWithoutOrderDiscount) == 'undefined' ? data.totals.subTotal : data.totals.subTotalWithoutOrderDiscount);
    $('.js-quantity-minibag').text(data.numItems);
    $('.items-label').text(utils.pluralize(data.numItems, 'Item'));
    $('.minicart-link').attr('title', 'Cart ' + data.numItems + ' Items');
    utils.updateCartCount(data.numItems);
    data.items.forEach(elem => {
        $('#itemprice-' + elem.UUID).text(elem.priceTotal.price.displayPrice);
        $(`.price-update-on-add-${elem.UUID}`).text(elem.priceTotal.nonAdjustedPrice.displayPrice);
    });

    // for CA site we are using this key to show subtotal
    if (data.totals.subTotalWithoutOrderDiscount) {
        $('.sub-total-without-discount').empty().append(data.totals.subTotalWithoutOrderDiscount);
    }
}

module.exports = {
    ...minicart,

    quantity: function () {
        $('body').on('change', '.quantity-form > .quantity', function () {
            var quantity = $(this).val();
            var uuid = $(this).data('uuid');
            var pid = $(this).data('pid');
            var url;
            $('.minicart-removed-msg').addClass('d-none');

            if (quantity === '0') {
                url = $(this).data('action-remove');
                minicart.miniCartZeroItemCount(url, uuid, pid);
            } else {
                var preSelectQty = $(this).data('pre-select-qty');
                url = $(this).data('action');
                var urlParams = {
                    pid: pid,
                    quantity: quantity,
                    uuid: uuid,
                    pageType: 'minicart'
                };
                url = utils.appendToUrl(url, urlParams);

                $.ajax({
                    url: url,
                    type: 'get',
                    context: this,
                    dataType: 'json',
                    success: function (data) {
                        var $this = $(this);

                        $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                        $('.qty-error-' + uuid).find('button').trigger('click');
                        $('.minicart__product_container').empty().html(data.renderTemplate);
                        updateMiniCartTotal(data);

                        $this.data('pre-select-qty', quantity);
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            if (err.responseJSON.message) {
                                let errorHtml = `${err.responseJSON.message}<a class="dismiss-alert-minibag" href="javascript:void(0);"> Dismiss</a>`;
                                let $errorContainer = $('.invalid-qty-error-' + uuid);
                                $errorContainer.find('.invalid-qty-error-text').empty().html(errorHtml);
                                $errorContainer.removeClass('d-none');
                            }
                            $(this).val(parseInt(preSelectQty, 10));
                            tealiumEnable.getAnalyticsData('serverError', err.responseJSON.errorCode);
                        }
                    }
                });
            }
        });
    }

};
